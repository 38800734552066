import {toast} from "react-toastify";
import i18n from "i18next";

const demoHelper = () => {
    toast.info(i18n.t('global.demo_message'), {
        position: i18n.language === 'fa' ? 'bottom-right' : 'bottom-left',
        bodyClassName: 'font-iyekan',
        theme: 'colored',
    });
};

export default demoHelper;
