import './App.css';
import {Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Courses from "./pages/Courses";
import Contact from "./pages/Contact";
import {HelmetProvider} from "react-helmet-async";
import 'react-toastify/dist/ReactToastify.css';
import NotFound from "./pages/NotFound";

function App() {
    return (
        <HelmetProvider>
            <div className="App bg-white dark:bg-gray-800">
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/projects" element={<Projects/>}/>
                    <Route path="/courses" element={<Courses/>}/>
                    <Route path="/contact" element={<Contact/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </div>
        </HelmetProvider>
    );
}

export default App;
