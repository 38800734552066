import React from 'react';
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

const HelmetCustom = ({children}) => {
    const {t} = useTranslation();

    return (
        <Helmet titleTemplate={`${t('global.prefix_title')} - %s`}>{children}</Helmet>
    );
};

export default HelmetCustom;