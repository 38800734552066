import Layout from "../components/Layout";
import "@lottiefiles/lottie-player";
import {useTranslation} from "react-i18next";
import HelmetCustom from "../components/HelmetCustom";
import demoHelper from "../helpers/DemoHelper";

const Contact = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <HelmetCustom>
                <title>{t('global.contact_title')}</title>
                <meta name="description" content={t('global.contact_description')}/>
            </HelmetCustom>
            <div className="h-screen">
                <div className="h-3/4">
                    <lottie-player src="./assets/jsons/96060-tta-contact-us.json" background="transparent" speed="1" loop autoplay />
                </div>
            </div>
            <div className="bg-gray-50 dark:bg-gray-600 shadow-xl p-10 max-w-3xl rounded-md mx-5 md:mx-auto -mt-52">
                <h4 className="text-2xl border-b border-b-gray-300 pb-3 mb-5 text-black dark:text-white">{t('contact.description')}</h4>
                <form action="#" method="post">
                    <input type="text" placeholder={t('contact.name')} className="border-2 border-gray-300 bg-white dark:bg-gray-300 dark:placeholder-black rounded-md shadow-xl w-full p-2 mb-8 outline-none focus:border-red-500"/>
                    <input type="email" placeholder={t('contact.email')} className="border-2 border-gray-300 bg-white dark:bg-gray-300 dark:placeholder-black rounded-md shadow-xl w-full p-2 mb-8 outline-none focus:border-red-500"/>
                    <textarea placeholder={t('contact.message')} className="border-2 border-gray-300 bg-white dark:bg-gray-300 dark:placeholder-black rounded-md shadow-xl w-full p-2 mb-8 outline-none focus:border-red-500"/>
                    <button onClick={(e) => {
                        e.preventDefault();
                        demoHelper();
                    }} type="submit" className="bg-red-500 px-10 py-2 text-white font-bold rounded-md shadow-xl transition hover:bg-red-700">{t('contact.submit')}</button>
                </form>
            </div>
        </Layout>
    );
};

export default Contact;