import {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {FaBars, FaGlobe, FaMoon, FaSun} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const [language, setLanguage] = useState('fa');
    const location = useLocation();
    const {t} = useTranslation();

    const listItems = [
        {
            title: t('header.menu.home'),
            link: '/',
        },
        {
            title: t('header.menu.projects'),
            link: '/projects',
        },
        {
            title: t('header.menu.courses'),
            link: '/courses',
        },
        {
            title: t('header.menu.contact'),
            link: '/contact',
        },
    ];

    const darkLangButton = () => (
        <>
            <button
                onClick={() => {
                    if (i18n.language === 'en'){
                        setLanguage('fa');
                    }
                    else{
                        setLanguage('en');
                    }
                }}
                className="rounded-full bg-gray-500 dark:bg-gray-100 text-white bg-opacity-40 dark:bg-opacity-40 hover:bg-opacity-80 dark:hover:bg-opacity-20 p-3 transition mx-3">
                <FaGlobe/></button>
            <button
                onClick={() => {
                    setDarkMode(!darkMode);
                }}
                className="rounded-full bg-gray-500 dark:bg-gray-100 text-white bg-opacity-40 dark:bg-opacity-40 hover:bg-opacity-80 dark:hover:bg-opacity-20 p-3 transition rtl:ml-3 ltr:mr-3">
                {darkMode ? (
                    <FaSun size="16"/>
                ) : (
                    <FaMoon size="16"/>
                )}
            </button>
        </>
    );

    useEffect(() => {
        const themeLocal = window.localStorage.getItem('theme');
        if (themeLocal)
            setDarkMode(themeLocal === 'dark');

        const languageLocal = window.localStorage.getItem('language');
        if (languageLocal)
            setLanguage(languageLocal);
    }, []);

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.add('dark');
            window.localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.classList.remove('dark');
            window.localStorage.setItem('theme', 'light');
        }
    }, [darkMode]);

    useEffect(() => {
        if (language === 'fa'){
            document.documentElement.dir = 'rtl';
            document.documentElement.lang = 'fa_IR';
            window.localStorage.setItem('language', 'fa');
            i18n.changeLanguage("fa");
        }else if (language === 'en'){
            document.documentElement.dir = 'ltr';
            document.documentElement.lang = 'en_US';
            window.localStorage.setItem('language', 'en');
            i18n.changeLanguage("en");
        }
    }, [language]);

    return (
        <header
            className="bg-gray-300 dark:bg-theme text-black dark:text-white p-2 fixed top-0 left-0 right-0 z-10 flex justify-between items-center flex-col md:flex-row shadow-lg font-iyekan">
            <div className="flex justify-between w-full md:w-auto">
                <h1><Link to="/"
                          className="inline-block text-2xl font-bold p-2 transition-all hover:text-yellow-900 dark:hover:text-yellow-500">{t('header.title')}</Link>
                </h1>
                <div className="flex items-center md:hidden">
                    {darkLangButton()}
                    <button className="fill-black md:fill-white" onClick={() => {
                        setShowMenu(!showMenu)
                    }}>
                        <FaBars size={32} color="currentColor"/>
                    </button>
                </div>
            </div>
            <div className="flex w-full md:w-auto">
                <ul className={`list-none w-full md:w-auto transition-all duration-500 overflow-hidden py-5 md:py-0 ${showMenu ? 'max-h-96' : 'max-h-0 py-0'} md:max-h-fit md:flex`}>
                    {listItems.map((item, index) => (
                        <li key={index}><Link to={item.link}
                                              className={`block md:inline-block py-2 px-4 transition-all m-2 md:m-0 ${location.pathname === item.link ? 'bg-gray-500 dark:bg-gray-100 text-white dark:text-black rounded' : 'hover:text-green-600 dark:hover:text-green-300'}`}>{item.title}</Link>
                        </li>
                    ))}
                </ul>
                <span className="hidden md:inline-block">
                    {darkLangButton()}
                </span>
            </div>
        </header>
    );
};

export default Header;