import Layout from "../components/Layout";
import '@lottiefiles/lottie-player';
import {FaCss3, FaHtml5, FaJsSquare, FaLaravel, FaNodeJs, FaReact} from "react-icons/fa";
import {SiFlutter, SiTailwindcss} from "react-icons/si";
import {useTranslation} from "react-i18next";
import HelmetCustom from "../components/HelmetCustom";

const Home = () => {
    const {t} = useTranslation();

    return (
        <Layout>
            <HelmetCustom>
                <title>{t('global.home_title')}</title>
                <meta name="description" content={t('global.home_description')}/>
            </HelmetCustom>
            {/* Top Section */}
            <section className="h-auto xl:h-screen bg-gray-300 dark:bg-theme">
                <div
                    className="grid grid-cols-1 lg:grid-cols-2 lg:items-center bg-gray-300 dark:bg-theme lg:border-4 border-gray-600 dark:border-gray-100 transform lg:rotate-12 lg:mx-16">
                    <div className="max-w-lg lg:max-w-none lg:h-3/4 mx-auto">
                        <lottie-player src="./assets/jsons/99312-developer-skills.json" background="transparent"
                                       speed="1" loop autoplay/>
                    </div>
                    <div className="text-black dark:text-white text-2xl md:text-4xl rtl:border-r-4 ltr:border-l-4 border-gray-600 dark:border-gray-100 px-2 mx-auto lg:mx-0 mt-5 mb-12">
                        <h2 className="font-bold">{t('home.hi')} <strong className="text-yellow-900 dark:text-yellow-500">{t('home.name')}</strong> {t('home.iam')}</h2>
                        <p className="border-t-2 border-gray-600 dark:border-gray-100 inline-block pt-3 mt-5">{t('home.programmer_and')} <strong className="text-red-600 dark:text-red-400">{t('home.web_designer')}</strong></p>
                    </div>
                </div>
            </section>
            {/* Technologies Section */}
            <section className="mt-20">
                <h2 className="text-center text-2xl md:text-4xl text-blue-700 dark:text-yellow-500 font-bold mb-20">{t('home.technologies_i_use')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mx-auto max-w-7xl gap-20">
                    <div className="mx-auto">
                        <FaHtml5 size={180} color="#f06529" title="HTML5"/>
                    </div>
                    <div className="mx-auto animate-bounce">
                        <FaCss3 size={180} color="#2965f1" title="CSS3"/>
                    </div>
                    <div className="mx-auto animate-bounce">
                        <FaJsSquare size={180} color="#F0DB4F" title="Javascript"/>
                    </div>
                    <div className="mx-auto">
                        <FaReact size={180} color="#61DBFB" title="ReactJS"/>
                    </div>
                    <div className="mx-auto animate-bounce">
                        <SiTailwindcss size={180} color="#36B7F0" title="TailwindCSS"/>
                    </div>
                    <div className="mx-auto">
                        <FaLaravel size={180} color="#F05340" title="Laravel"/>
                    </div>
                    <div className="mx-auto">
                        <FaNodeJs size={180} color="#3C873A" title="NodeJS"/>
                    </div>
                    <div className="mx-auto animate-bounce">
                        <SiFlutter size={180} color="#5DC3F0" title="Flutter"/>
                    </div>
                </div>
            </section>
            {/* Different Technologies Section */}
            <section className="mt-20">
                <div className="bg-primary pt-10 pb-48 text-white text-center">
                    <h2 className="text-2xl md:text-4xl font-bold">{t('home.different_technologies_title')}</h2>
                </div>
                <div
                    className="bg-gray-50 dark:bg-gray-600 text-black dark:text-white transition hover:bg-gray-600 dark:hover:bg-gray-900 hover:text-white shadow-2xl -mt-32 mx-5 md:mx-40 rounded-md">
                    <div className="h-[30rem]">
                        <lottie-player src="./assets/jsons/96512-developer-animation.json" background="transparent"
                                       speed="1" loop autoplay/>
                    </div>
                    <p className="p-10 pt-0 text-base md:text-lg">{t('home.different_technologies_description')}</p>
                </div>
            </section>
            {/* Dev Stack Section */}
            <section className="mt-20">
                <div className="bg-red-400 pt-10 pb-48 text-white text-center">
                    <h2 className="text-2xl md:text-4xl font-bold">{t('home.my_dev_stack')}</h2>
                </div>
                <div
                    className="bg-gray-50 dark:bg-gray-600 text-black dark:text-white transition hover:bg-gray-600 dark:hover:bg-gray-900 hover:text-white shadow-2xl -mt-32 mx-5 md:mx-40 rounded-md">
                    <div className="h-[30rem]">
                        <lottie-player src="./assets/jsons/89964-designer.json" background="transparent"
                                       speed="1" loop autoplay/>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-14 md:gap-4 p-10 pt-0"
                         style={{direction: 'LTR'}}>
                        <div>
                            <h4 className="text-3xl font-bold pb-3 border-b border-gray-500 mb-3">FrontEnd</h4>
                            <ul className="list-none font-bold flex flex-col gap-3 text-base">
                                <li>HTML/CSS</li>
                                <li>Javascript</li>
                                <li>ReactJS</li>
                                <li>Redux</li>
                                <li>VueJS</li>
                            </ul>
                        </div>
                        <div>
                            <h4 className="text-3xl font-bold pb-3 border-b border-gray-500 mb-3">UI / UX</h4>
                            <ul className="list-none font-bold flex flex-col gap-3 text-base">
                                <li>Adobe XD</li>
                                <li>Figma</li>
                                <li>TailwindCSS</li>
                                <li>Material UI</li>
                                <li>Bootstrap</li>
                            </ul>
                        </div>
                        <div>
                            <h4 className="text-3xl font-bold pb-3 border-b border-gray-500 mb-3">Backend / DB</h4>
                            <ul className="list-none font-bold flex flex-col gap-3 text-base">
                                <li>PHP</li>
                                <li>Laravel</li>
                                <li>NodeJS</li>
                                <li>MySQL</li>
                                <li>MongoDB</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {/* Who Is */}
            <section className="mt-20 -mb-20 md:-mb-40">
                <h2 className="font-bold text-2xl md:text-4xl text-center text-black dark:text-white">{t('home.who_is_me')}</h2>
                <div className="md:h-screen">
                    <div className="h-full p-6">
                        <div className="relative h-full" style={{direction: 'ltr'}}>
                            <lottie-player src="./assets/jsons/80683-blob.json" background="transparent"
                                           speed="1" loop autoplay/>
                            <div className="absolute inset-0 flex flex-col justify-center items-center text-gray-500 dark:text-gray-100 font-bold">
                                <h4 className="text-base md:text-2xl mb-5">Hi, I'm A Web Programmer</h4>
                                <pre className="text-sm md:text-xl">{JSON.stringify({
                                    name: 'Mohammad Mehrabi',
                                    from: 'Iran - Esfahan',
                                    age: 21,
                                    gender: 'Male',
                                    'work-in': null,
                                    'ready-to-work': true,
                                }, null, 2)}</pre>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Home;