import {FaEnvelope, FaFacebook, FaGithub, FaInstagram, FaLinkedin} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import demoHelper from "../helpers/DemoHelper";
import i18n from "i18next";
import {ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";

const Footer = () => {
    const {t} = useTranslation();

    const clickDemo = (e) => {
        e.preventDefault();
        demoHelper();
    };

    return (
        <>
            <footer>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path className="fill-gray-300 dark:fill-theme" fillOpacity="1"
                          d="M0,128L48,117.3C96,107,192,85,288,117.3C384,149,480,235,576,250.7C672,267,768,213,864,202.7C960,192,1056,224,1152,229.3C1248,235,1344,213,1392,202.7L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
                <div className="bg-gray-300 dark:bg-theme text-black dark:text-white p-10 pt-0 font-iyekan text-center">
                    <p>{t('footer.developed_by')}</p>
                    <ul className="list-none flex gap-4 justify-between items-center max-w-2xl mx-auto py-2 my-2 border-t-2 border-b-2 border-gray-600 dark:border-gray-200 border-dotted">
                        <li><Link to="#" onClick={(e) => {
                            clickDemo(e)
                        }} className="transition hover:text-red-800 dark:hover:text-red-200"><FaFacebook size={20}/></Link>
                        </li>
                        <li><Link to="#" onClick={(e) => {
                            clickDemo(e)
                        }} className="transition hover:text-red-800 dark:hover:text-red-200"><FaInstagram
                            size={20}/></Link></li>
                        <li><Link to="#" onClick={(e) => {
                            clickDemo(e)
                        }} className="transition hover:text-red-800 dark:hover:text-red-200"><FaEnvelope size={20}/></Link>
                        </li>
                        <li><Link to="#" onClick={(e) => {
                            clickDemo(e)
                        }} className="transition hover:text-red-800 dark:hover:text-red-200"><FaLinkedin size={20}/></Link>
                        </li>
                        <li><Link to="#" onClick={(e) => {
                            clickDemo(e)
                        }} className="transition hover:text-red-800 dark:hover:text-red-200"><FaGithub size={20}/></Link>
                        </li>
                    </ul>
                    <p>{t('footer.developed_name')}</p>
                </div>
            </footer>
            <ToastContainer rtl={i18n.language === 'fa'}/>
        </>
    );
};

export default Footer;