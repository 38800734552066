import Layout from "../components/Layout";
import {useTranslation} from "react-i18next";
import HelmetCustom from "../components/HelmetCustom";
import demoHelper from "../helpers/DemoHelper";

const Courses = () => {
    const { t } = useTranslation();

    const coursesItems = [
        {
            title: t('courses.course1_name'),
            image: 'reactjs.jpg',
            link: '/',
        },
        {
            title: t('courses.course2_name'),
            image: 'nodejs.png',
            link: '/',
        },
        {
            title: t('courses.course3_name'),
            image: 'mernstack.png',
            link: '/',
        },
        {
            title: t('courses.course4_name'),
            image: 'frontend.jpg',
            link: '/',
        },
        {
            title: t('courses.course5_name'),
            image: 'fullstack.png',
            link: '/',
        },
        {
            title: t('courses.course6_name'),
            image: 'javascript.jpg',
            link: '/',
        },
    ];

    return (
        <Layout>
            <HelmetCustom>
                <title>{t('global.courses_title')}</title>
                <meta name="description" content={t('global.courses_description')}/>
            </HelmetCustom>
            <div className="h-screen">
                <div className="h-3/4 bg-gray-300 dark:bg-theme">
                    <lottie-player src="./assets/jsons/79983-online-marketing-or-teaching.json"
                                   background="transparent" speed="1" loop autoplay/>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path className="fill-gray-300 dark:fill-theme" fillOpacity="1" d="M0,224L48,192C96,160,192,96,288,101.3C384,107,480,181,576,218.7C672,256,768,256,864,224C960,192,1056,128,1152,106.7C1248,85,1344,107,1392,117.3L1440,128L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
                </svg>
            </div>
            <p className="text-center text-base lg:text-xl mx-3 lg:mt-32 text-black dark:text-white">{t('courses.description')}</p>
            <strong className="block mt-5 text-center text-2xl lg:text-4xl font-bold text-black dark:text-white">{t('courses.because')}</strong>
            <div
                className="bg-gray-500 text-center text-white text-2xl lg:text-7xl font-bold py-10 lg:py-20 mx-5 lg:mx-28 mt-10 rounded-tl-full rounded-br-full">
                <h4>{t('courses.learning_is')}</h4>
                <h4 className="mt-5">{t('courses.continuous')}</h4>
            </div>
            <div className="m-20">
                <ul className="list-none grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 mxa">
                    {coursesItems.map((item, index) => (
                        <li key={index}>
                            <article className="w-full flex justify-center items-center h-full border border-gray-400 rounded-tr-3xl rounded-bl-3xl relative">
                                <img src={`./assets/images/courses/${item.image}`} alt={item.title} className="h-60 w-full rounded-tr-3xl rounded-bl-3xl"/>
                                <div className="absolute inset-0 flex flex-col justify-center items-center gap-5 opacity-0 transition hover:bg-black hover:opacity-80 rounded-tr-3xl rounded-bl-3xl">
                                    <h5 className="text-2xl font-bold text-white">{item.title}</h5>
                                    <button onClick={demoHelper} className="text-white border-2 border-gray-50 px-10 py-2 rounded-lg transition hover:bg-green-600">{t('courses.demo')}</button>
                                </div>
                            </article>
                        </li>
                    ))}
                </ul>
            </div>
        </Layout>
    );
};

export default Courses;