import Layout from "../components/Layout";
import "@lottiefiles/lottie-player";
import {useTranslation} from "react-i18next";
import HelmetCustom from "../components/HelmetCustom";
import demoHelper from "../helpers/DemoHelper";

const Projects = () => {
    const { t } = useTranslation();

    const projectsItems = [
        {
            title: t('projects.project1_name'),
            image: 'ecommerce.svg',
            link: '/',
        },
        {
            title: t('projects.project2_name'),
            image: 'pizzas.svg',
            link: '/',
        },
        {
            title: t('projects.project3_name'),
            image: 'hotels.svg',
            link: '/',
        },
        {
            title: t('projects.project4_name'),
            image: 'jobs.svg',
            link: '/',
        },
        {
            title: t('projects.project5_name'),
            image: 'socialmedia.svg',
            link: '/',
        },
        {
            title: t('projects.project6_name'),
            image: 'cars.svg',
            link: '/',
        },
    ];

    return (
        <Layout>
            <HelmetCustom>
                <title>{t('global.projects_title')}</title>
                <meta name="description" content={t('global.projects_description')}/>
            </HelmetCustom>
            <div className="h-screen">
                <div className="h-3/4">
                    <lottie-player src="./assets/jsons/71159-mobile-app-development-design.json"
                                   background="transparent" speed="1" loop autoplay/>
                </div>
            </div>
            <p className="text-center text-base lg:text-xl mx-3 -mt-52 text-black dark:text-white">{t('projects.description')}</p>
            <strong className="block mt-5 text-center text-2xl lg:text-4xl font-bold text-black dark:text-white">{t('projects.because')}</strong>
            <div
                className="bg-red-500 text-center text-white text-2xl lg:text-7xl font-bold py-10 lg:py-20 mx-5 lg:mx-28 mt-10 rounded-tl-full rounded-br-full">
                <h4>{t('projects.the_game_is')}</h4>
                <h4 className="mt-5">{t('projects.consistency')}</h4>
            </div>
            <div className="m-20">
                <ul className="list-none grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 mxa">
                    {projectsItems.map((item, index) => (
                        <li key={index}>
                            <article className="w-full flex justify-center items-center h-full p-3 border border-gray-400 rounded-tr-3xl rounded-bl-3xl relative">
                                <img src={`./assets/images/projects/${item.image}`} alt={item.title} className="h-60 w-full"/>
                                <div className="absolute inset-0 flex flex-col justify-center items-center gap-5 opacity-0 transition hover:bg-black hover:opacity-80 rounded-tr-3xl rounded-bl-3xl">
                                    <h5 className="text-2xl font-bold text-white">{item.title}</h5>
                                    <button onClick={demoHelper} className="text-white border-2 border-gray-50 px-10 py-2 rounded-lg transition hover:bg-green-600">{t('projects.demo')}</button>
                                </div>
                            </article>
                        </li>
                    ))}
                </ul>
            </div>
        </Layout>
    );
};

export default Projects;