export const TRANSLATIONS_FA = {
    global: {
        "prefix_title": "محمد مهرابی",
        "home_title": "صفحه اصلی",
        "projects_title": "پروژه ها",
        "courses_title": "دوره ها",
        "contact_title": "تماس با ما",
        "home_description": "صفحه اصلی سایت من که شامل نمونه پروژه طراحی شده با ری اکت می باشد و امکانات مختلفی در آن قرار دارد.",
        "projects_description": "پروژه های طراحی شده توسط من همراه با امکان مشاهده پیش نمایش آنها در این صفحه قابل دسترس میباشد.",
        "courses_description": "در این صفحه به آخرین دوره های طراحی شده توسط من دسترسی و امکان تهیه و مشاهده آنها را دارید.",
        "contact_description": "در صورت تمایل به برقراری ارتباط با من در زمینه های کاری مختلف، می توانید از طریق فرم موجود در این صفحه اقدام کنید.",
        "demo_message": "این سایت صرفا برای تست ساخته شده و این مورد کار نمی کند.",
        "404_title": "404 - صفحه یافت نشد",
        "404_description": "خطای 404 - صفحه مورد نظر پیدا نشد",
        "404_message_first": "خطای 404",
        "404_message_second": "صفحه مورد نظر پیدا نشد!",
    },
    header: {
        "title": "محمد مهرابی",
        "menu": {
            "home": "خانه",
            "projects": "پروژه ها",
            "courses": "دوره ها",
            "contact": "تماس با ما",
        },
    },
    footer: {
        "developed_by": "طراحی و توسعه توسط:",
        "developed_name": "محمد مهرابی",
    },
    home: {
        "hi": "سلام، من",
        "name": "محمد",
        "iam": "هستم",
        "programmer_and": "برنامه نویس و",
        "web_designer": "طراح وب",
        "technologies_i_use": "تکنولوژی های مورد استفاده من",
        "different_technologies_title": "بله؛ درسته! من مسلط به تکنولوژی های مختلف هستم",
        "different_technologies_description": "استفاده از تکنولوژی های مختلف در قالب چند زبان برنامه نویسی محدود مثل جاوا اسکریپت که تکنولوژی های طراحی وب، برنامه نویسی وب و موبایل را دارا می باشد، امکان پذیر شده است و می توان با یادگیری یک زبان و تکنولوژی های مختلف آن، به برنامه نویس فول استک تبدیل شد.",
        "my_dev_stack": "من در زمینه های مختلف",
        "who_is_me": "من کیستم؟",
    },
    projects: {
        "description": "ایده های خوب به طور خودکار اجرا نمی شوند. آنها باید با شکیبایی و شجاعانه وارد عمل شوند.",
        "because": "زیرا",
        "the_game_is": "روش بازی این است...",
        "consistency": "اجرا و اثبات کنید!",
        "project1_name": "پروژه فروشگاه",
        "project2_name": "پروژه فست فود",
        "project3_name": "پروژه هتل",
        "project4_name": "پروژه کاریابی",
        "project5_name": "پروژه شبکه اجتماعی",
        "project6_name": "پروژه نمایشگاه ماشین",
        "demo": "پیشنمایش",
    },
    courses: {
        "description": "«من نمیتوانم به مردم هر آنچه را که باید بدانند بیاموزم. بهترین کاری که میتوانم انجام دهم این است که آنها را در مسیری قرار دهم که در صورت نیاز، آنچه را که باید بدانند بیابند.»",
        "because": "زیرا",
        "learning_is": "یادگیری مداوم...",
        "continuous": "و دست یافتنی است",
        "course1_name": "دوره ری اکت",
        "course2_name": "دوره نود جی اس",
        "course3_name": "دوره مرن استک",
        "course4_name": "دوره فرانت اند",
        "course5_name": "دوره فول استک",
        "course6_name": "دوره جاوا اسکریپت",
        "demo": "مشاهده دوره",
    },
    contact: {
        "description": "برای اطلاعات بیشتر با ما تماس بگیرید",
        "name": "نام...",
        "email": "ایمیل...",
        "message": "متن پیام...",
        "submit": "ثبت درخواست",
    },
};
