import Header from "./Header";
import Footer from "./Footer";
import {useEffect} from "react";

const Layout = ({children}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <main className="font-iyekan mt-16">
                {children}
            </main>
            <Footer />
        </>
    );
};

export default Layout;