import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {TRANSLATIONS_EN} from "./en/translations";
import {TRANSLATIONS_FA} from "./fa/translations";

i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'fa',
        resources: {
            en: {
                translation: TRANSLATIONS_EN,
            },
            fa: {
                translation: TRANSLATIONS_FA,
            },
        },
    });
