export const TRANSLATIONS_EN = {
    global: {
        "prefix_title": "Mohammad Mehrabi",
        "home_title": "Main Page",
        "projects_title": "Projects",
        "courses_title": "Courses",
        "contact_title": "Contact Us",
        "home_description": "The main page of my site, which includes a sample project designed with React and has various features.",
        "projects_description": "The projects designed by me are available on this page along with the possibility of viewing their preview.",
        "courses_description": "On this page, you have access to the latest courses designed by me and the possibility of preparing and viewing them.",
        "contact_description": "If you want to contact me in different fields of work, you can apply through the form on this page.",
        "demo_message": "This site is for testing purposes only and this does not work.",
        "404_title": "404 - Page Not Found",
        "404_description": "404 Error - Page Not Found",
        "404_message_first": "404 Error",
        "404_message_second": "Page Not Found!",
    },
    header: {
        "title": "Mohammad Mehrabi",
        "menu": {
            "home": "Home",
            "projects": "Projects",
            "courses": "Courses",
            "contact": "Contact Us",
        },
    },
    footer: {
        "developed_by": "Design And Developed by",
        "developed_name": "Mohammad Mehrabi",
    },
    home: {
        "hi": "Hi, I'm",
        "name": "Mohammad",
        "iam": "",
        "programmer_and": "Programmer &",
        "web_designer": "Web Designer",
        "technologies_i_use": "Technologies I Use",
        "different_technologies_title": "Yes; it's true! I am fluent in different technologies",
        "different_technologies_description": "The use of different technologies in the form of limited multi-programming languages such as JavaScript, which has web design, web programming and mobile technologies, has become possible and can be learned by learning one language and its different technologies. Became a full stack developer.",
        "my_dev_stack": "My Dev Stack",
        "who_is_me": "Who am I?",
    },
    projects: {
        "description": "Good ideas are not adopted automatically. They must be driven into practice with courageous patience",
        "because": "Because",
        "the_game_is": "THE GAME IS...",
        "consistency": "CONSISTENCY",
        "project1_name": "Shop Project",
        "project2_name": "Pizza Project",
        "project3_name": "Rooms Project",
        "project4_name": "Jobs Project",
        "project5_name": "Gram Project",
        "project6_name": "Cars Project",
        "demo": "DEMO",
    },
    courses: {
        "description": "“I can't teach people everything they need to know. The best I can do is position them where they can find what they need to know when they need to know.”",
        "because": "Because",
        "learning_is": "LEARNING IS...",
        "continuous": "CONTINUOUS",
        "course1_name": "React Developer Course",
        "course2_name": "Node JS Course",
        "course3_name": "MERN Stack Bootcamp",
        "course4_name": "Frontend Course",
        "course5_name": "Fullstack Web Development",
        "course6_name": "Javascript Mastery",
        "demo": "Show Course",
    },
    contact: {
        "description": "Contact us to more",
        "name": "Name...",
        "email": "Email...",
        "message": "Message...",
        "submit": "Submit",
    },
};
