import React from 'react';
import Layout from "../components/Layout";
import HelmetCustom from "../components/HelmetCustom";
import {useTranslation} from "react-i18next";
import '@lottiefiles/lottie-player';

const NotFound = () => {
    const {t} = useTranslation();

    return (
        <Layout>
            <HelmetCustom>
                <title>{t('global.404_title')}</title>
                <meta name="description" content={t('global.404_description')}/>
            </HelmetCustom>
            <div className="h-screen pt-10">
                <div className="h-3/4">
                    <lottie-player src="./assets/jsons/94905-404-not-found.json" background="transparent" speed="1" loop
                                   autoplay/>
                </div>
            </div>
            <div className="-mt-40 text-center text-black dark:text-white">
                <h4 className="text-4xl font-bold">{t('global.404_message_first')}</h4>
                <h4 className="text-2xl mt-5">{t('global.404_message_second')}</h4>
            </div>
        </Layout>
    );
};

export default NotFound;
